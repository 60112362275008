import React from "react";
import CheckBoxComponent from "./CheckBoxComponent";
import { handleCheckboxChange } from "../../Transaction";

function Mode({ setSelectedItem, selectedItems, filter, setFilters }: any) {
  return (
    <div className="flex flex-wrap items-center gap-2">
      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          onChange={() =>
            handleCheckboxChange("paymentMode", "upi", setFilters)
          }
          id={"upi"}
          name={"upi"}
          checked={filter.upi}
        />
        <label
          htmlFor={"upi"}
          className={filter.upi ? "text-black " : " text-gray-400"}
        >
          Upi
        </label>
      </div>
      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          onChange={() =>
            handleCheckboxChange("paymentMode", "credit_card", setFilters)
          }
          id={"creditcard"}
          name={"creditcard"}
          checked={filter.credit_card}
        />
        <label
          htmlFor={"creditcard"}
          className={filter.credit_card ? "text-black " : " text-gray-400"}
        >
          Credit Card
        </label>
      </div>
      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          onChange={() =>
            handleCheckboxChange("paymentMode", "credit_card_emi", setFilters)
          }
          id={"creditcardemi"}
          name={"creditcardemi"}
          checked={filter.credit_card_emi}
        />
        <label
          htmlFor={"creditcardemi"}
          className={filter.credit_card_emi ? "text-black " : " text-gray-400"}
        >
          Credit Card Emi
        </label>
      </div>
      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          onChange={() =>
            handleCheckboxChange("paymentMode", "wallet", setFilters)
          }
          id={"wallet"}
          name={"wallet"}
          checked={filter.wallet}
        />
        <label
          htmlFor={"wallet"}
          className={filter.wallet ? "text-black " : " text-gray-400"}
        >
          Wallet
        </label>
      </div>
      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          onChange={() =>
            handleCheckboxChange("paymentMode", "pay_later", setFilters)
          }
          id={"paylater"}
          name={"paylater"}
          checked={filter.pay_later}
        />
        <label
          htmlFor={"paylater"}
          className={filter.pay_later ? "text-black " : " text-gray-400"}
        >
          Pay later
        </label>
      </div>
      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          onChange={() =>
            handleCheckboxChange("paymentMode", "cardless_emi", setFilters)
          }
          id={"cardlessemi"}
          name={"cardlessemi"}
          checked={filter.cardless_emi}
        />
        <label
          htmlFor={"cardlessemi"}
          className={filter.cardless_emi ? "text-black " : " text-gray-400"}
        >
          Cardless Emi
        </label>
      </div>
      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          onChange={() =>
            handleCheckboxChange("paymentMode", "net_banking", setFilters)
          }
          id={"netbanking"}
          name={"netbanking"}
          checked={filter.net_banking}
        />
        <label
          htmlFor={"netbanking"}
          className={filter.net_banking ? "text-black " : " text-gray-400"}
        >
          Net Banking
        </label>
      </div>
      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          onChange={() =>
            handleCheckboxChange("paymentMode", "debit_card", setFilters)
          }
          id={"debitcard"}
          name={"debitcard"}
          checked={filter.debit_card}
        />
        <label
          htmlFor={"debitcard"}
          className={filter.debit_card ? "text-black " : " text-gray-400"}
        >
          Debit Card
        </label>
      </div>
      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          onChange={() =>
            handleCheckboxChange("paymentMode", "debit_card_emi", setFilters)
          }
          id={"debitcardemi"}
          name={"debitcardemi"}
          checked={filter.debit_card_emi}
        />
        <label
          htmlFor={"debitcardemi"}
          className={filter.debit_card_emi ? "text-black " : " text-gray-400"}
        >
          Debit Card Emi
        </label>
      </div>
    </div>
  );
}

export default Mode;
