import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, Routes, Route, Navigate, NavLink } from "react-router-dom";
import { Sidebar, SidebarItem } from "../../components/Sidebar";
import { AiFillHome } from "react-icons/ai";
import { BiLogInCircle } from "react-icons/bi";

import SchoolList from "./components/school/SchoolList";
import AllSchoolList from "./components/school/AllSchoolList";
import ApiKey from "./components/ApiKey/ApiKey";
import { useApolloClient, useQuery } from "@apollo/client";
import Settlement from "./Settlement/Settlement";
import { CiReceipt } from "react-icons/ci";
import { FaCode } from "react-icons/fa6";
import Transaction from "./Transaction/Transaction";
import Navbar from "../../components/Navbar";
import Overview from "./Overview/Overview";
import { FaSchool } from "react-icons/fa6";
import Profile from "./Profile/Profile";
import TeamMember from "./TeamMember/TeamMembers";
import ChangePassword from "./ChangePassword/ChangePassword";

import {
  FETCH_ALL_COMMISSION,
  GET_ALL_SCHOOLS_QUERY,
  GET_SETTLEMENT_REPORTS,
  GET_TRANSACTION_REPORT,
  GET_USER,
} from "../../Qurries";
import Payments from "./Payments/Payments";

import DevSideBar from "./components/ApiKey/DevSideBar";

import GeneratePgLink from "../GeneratePgLink/GeneratePgLink";

import TransactionReceipt from "./Transaction/TransactionReceipt";
import Institute from "./components/school/Institute";
import TransactionsOfSettlement from "./Settlement/TransactionsOfSettlement";
import { getStartAndEndOfMonth } from "../../utils/getStartAndEndOfMonth";
import ReceiptOfReconciliation from "./Payments/ReceiptOfReconciliation";

export enum Access {
  ADMIN = "admin",
  MANAGEMENT = "management",
  OWNER = "owner",
  FINANCE_TEAM = "finance_team",
}
export const dashboardContext = createContext<any>(null);
function Dashboard({ user, set_user }: any) {
  const [menu, setMenu] = useState(false);
  const [devMenu, setDevMenu] = useState(false);
  const [password, setPassword] = useState("");
  const [transaction, setTransaction] = useState<any>(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const { data: settlementData } = useQuery(GET_SETTLEMENT_REPORTS);
  const [testData, setTestData] = useState([]);
  const { startDate, endDate } = getStartAndEndOfMonth();
  const { data: transactionReport, loading: transactionReportLoading } =
    useQuery(GET_TRANSACTION_REPORT, {
      variables: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  const { data: commissionReport } = useQuery(FETCH_ALL_COMMISSION);
  const [commissionTotalAmount, setTotalCommission] = useState(0);

  useEffect(() => {
    if (commissionReport?.fetchAllCommission) {
      setTotalCommission(
        commissionReport?.fetchAllCommission
          .filter((s: any) => {
            const currentMonth = new Date().getMonth();
            return new Date(Number(s.createdAt))?.getMonth() === currentMonth;
          })
          .map((d: any) => d.commission_amount)
          .reduce((a: any, b: any) => a + b, 0) || 0,
      );
    }
  }, [commissionReport]);
  const client = useApolloClient();
  const handleLogout = () => {
    localStorage.removeItem("token");
    set_user(null);
    client.cache.evict({ fieldName: "getSchoolQuery" });
    client.cache.gc();
    client.cache.reset();
  };
  const platform_types = [
    "CreditCard",
    "DebitCard",
    "UPI",
    "NetBanking",
    "Wallet",
    "PayLater",
    "CardLess EMI",
    "INTERNATIONAL PG",
    "CORPORATE CARDS",
  ];

  const NEW_PLATFORM_TYPE = user?.getUserQuery?.base_mdr?.platform_charges.map(
    (d: any) => {
      if (platform_types.includes(d?.platform_type)) {
        return {
          label: d?.platform_type,
          value: [
            {
              label: d.payment_mode,
              value: d.payment_mode,
              range: d?.range_charge,
            },
          ],
        };
      }
    },
  );

  const platform_type = NEW_PLATFORM_TYPE?.reduce((acc: any, current: any) => {
    const existing = acc.find((item: any) => item.label === current.label);
    if (existing) {
      existing.value = [...existing.value, ...current.value];
    } else {
      acc.push({
        label: current.label,
        value: [...current.value],
      });
    }
    return acc;
  }, []);
  useEffect(() => {
    const pageRefreshTimer = setInterval(() => {
      window.location.reload();
    }, 1800000);

    return () => clearInterval(pageRefreshTimer);
  }, []);

  if (!user) return <Navigate to="/login"></Navigate>;

  return (
    <dashboardContext.Provider
      value={{
        user,
        set_user,
        settlementData,
        transactionReport,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        testData,
        setTestData,
        NEW_PLATFORM_TYPE,
        platform_type,
        transaction,
        setTransaction,
        commissionTotalAmount,
        setTotalCommission,
        commissionReport,
      }}
    >
      <div className="dashboard flex w-full">
        <div className="h-full ">
          <Sidebar
            schoolName={"Edviron"}
            Link={NavLink}
            menu={menu}
            setMenu={setMenu}
            setDevMenu={setDevMenu}
          >
            <SidebarItem
              icon={<AiFillHome className={"text-lg"} />}
              name="Home"
              to="/"
            />
            <SidebarItem
              icon={<FaSchool className={"text-lg"} />}
              name="Institute"
              to="/institute"
            />

            <SidebarItem
              icon={<CiReceipt className={"text-lg"} />}
              name="Payments"
              to="/payments"
            />

            {user?.getUserQuery?.role !== Access.FINANCE_TEAM ? (
              <SidebarItem
                icon={<FaCode className={"text-lg"} />}
                name="Developer"
                to="/dev"
              />
            ) : (
              <></>
            )}

            {/* <SidebarItem
              icon={<FaCode className={"text-lg"} />}
              name="Pg Link"
              to="/pg-link"
            /> */}

            <SidebarItem
              className="mt-auto"
              icon={<BiLogInCircle className={"text-lg"} />}
              name="Log Out"
              onTap={handleLogout}
            />
          </Sidebar>
        </div>

        <div
          className={
            "flex flex-col overflow-hidden flex-1 relative  w-full bg-[#EDF1F4] pl-[5rem]"
          }
        >
          <Navbar user={user} logOut={handleLogout} />

          <div className="flex flex-col transition-transform pt-[3rem] min-h-screen duration-200">
            <div className="flex overflow-hidden flex-col w-full  z-10 lg:pr-8 pr-4">
              <Routes>
                <Route path="/" element={<Overview />} />
                <Route
                  path="/developer"
                  element={
                    <div className="w-full mr-6 pl-6">
                      <ApiKey />
                    </div>
                  }
                />
                <Route path="/institute/*" element={<Institute />} />
                <Route path="/payments/*" element={<Payments menu={menu} />} />
                <Route path="/" element={<AllSchoolList />} />
                <Route path="/dev/*" element={<DevSideBar menu={menu} />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/team-members" element={<TeamMember />} />
                {/* <Route path="/pg-link" element={<GeneratePgLink />} /> */}
                <Route
                  path="/payments/transaction-receipt"
                  element={<TransactionReceipt />}
                />
                <Route
                  path="/payments/reconciliation-receipt"
                  element={<ReceiptOfReconciliation />}
                />
                <Route
                  path="/payments/settlements-transaction"
                  element={<TransactionsOfSettlement />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </dashboardContext.Provider>
  );
}

export default Dashboard;
